import { AxiosResponse } from "axios";
import FilesFormater from "@/modules/FPKG-220000-CasinoCore/converter/responseFileFormater";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通
import { ElMessageBox } from "element-plus"; // 取得警告標籤
import { ACTIVECODE } from "@/modules/types/statusCode";
const API = "frontendAdapter/common/prospects";

export namespace typeInfo {
  // export interface request {  }
  export type AxiosResponse = _AxiosResponse;
  export interface response {
    p11_site_config_info: p11_site_config_info;
    p3_account: p3_account;
    p17_member: p17_member;
    /** 客戶銀行 */
    p17_member_trade_account: p17_member_trade_account[];
    /** 客戶錢包 */
    p25_wallet: p25_wallet;
    /** 自訂區塊 */
    p36_cms_customization_block: p36_cms_customization_block[];
    /** 廣告區塊 */
    p37_ad_advertise_block: p37_ad_advertise_block[];
    // 客服
    p38_casino_customer_service: p38_casino_customer_service;
  }

  export type p11_site_config_info = {
    /** 站台敘述 */
    p11_scg_description: string;
    /** google tag header 部分 */
    p11_scg_google_tag_manager_head: string;
    /** google tag body 部分 */
    p11_scg_google_tag_manager_noscript: string;
    /** 站台關鍵字 */
    p11_scg_keyword: string;
    /** 站台 title */
    p11_scg_title: string;
    // 以下為快速登入的平台啟用與否
    line_oauth: ACTIVECODE;
    facebook_oauth: ACTIVECODE;
    google_oauth: ACTIVECODE;
    telegram_oauth: ACTIVECODE;
  };

  export type p38_casino_customer_service = {
    p38_ccse_facebook: string | null;
    p38_ccse_instagram: string | null;
    p38_ccse_messenger: string | null;
    p38_ccse_viber: string | null;
    p38_ccse_whatsapp: string | null;
    /**  "AAAA123" */
    p38_ccse_line_id: string | null;
    /**  "https://timeline.line.me/user/_dXO4y11re2e83AxBOE9X04ruaXizyAkbpsEavKE?utm_medium=windows&utm_source=desktop&utm_campaign=Profile" */
    p38_ccse_line_link: string | null;
    /**  "36456563" */
    p38_ccse_tawk: string | null;
    p38_ccse_salesmartly: string | null;
    /**  "https://web.telegram.org/" */
    p38_ccse_telegram: string | null;
  } | null;

  type p3_account = {
    /** "qq12345"; */
    p3_ant_account: string;
    /** 1618212132; */
    p3_ant_created_at: number;
    /** 146; */
    p3_ant_id: number;
    /** 1621154501; */
    p3_ant_last_login_time: number;
    /** null; */
    p3_ant_memo?: null | string;
    /** 94; */
    p3_ant_sort: number;
    /** "3"; */
    p3_ant_status: status;
  };

  export type p17_member = {
    /** "qq12345"; */
    p3_ant_account: string;
    /** 146; */
    p3_ant_id: number;
    /** "qq12345"; */
    p3_ape_name: string;
  };

  export type p17_member_trade_account = {
    p7_file: p7_file[];
    /** string json */
    p17_mta_data: string;
    p17_mta_id: number;
    /** 對應 p17_mta_account  */
    p17_mta_main_data: string;
    p17_mta_p3_ant_id: number;
    /** 審核備註 */
    p17_mta_review_memo?: null | string;
    /** 審核狀態 ; 3:通過 ,2:待審 ,-3:不通過 */
    p17_mta_review_status: "3" | "2" | "-3";
    p17_mta_reviewed_at: number;
    p17_mta_reviewer_p3_ant_id: number;
    p17_mta_status: status;
    p17_mta_type: "BANK" | "USDT";
  };

  type p25_wallet = {
    p24_wet_p3_ant_id: number;
    /** "485701.5000" */
    p24_wet_point: string;
    p24_wet_updated_at: number;
  };

  /** 自訂區塊 */
  export type p36_cms_customization_block = {
    files: p7_file[];
    /* 區塊序號 */
    p36_cbk_block_number: number;
    /* 內文 html */
    p36_cbk_content: null;
    /* 裝置 */
    p36_cbk_drive_type: "PC" | "MOBILE";
    p36_cbk_id: number;
    p36_cbk_memo: null | string;
    /** 區塊標題 */
    p36_cbk_name: string;
    p36_cbk_status: status;
    p36_cbk_updated_at: number;
  };
  export type p37_ad_advertise_block = {
    /* 區塊序號 */
    p37_abk_block_number: number;
    p37_abk_drive_type: "PC" | "MOBILE";
    p37_abk_end_time: number;
    p37_abk_id: number;
    /* 廣告區塊ID */
    p37_abk_name: string;
    p37_abk_start_time: number;
    p37_abk_status: status;
    p37_abk_updated_at: number;
    content: content[];
  };
  export type content = {
    files: p7_file[];
    /* 廣告內文 html */
    p37_abct_content: string;
    p37_abct_id: number;
    p37_abct_image_title_p7_file_id: number;
    p37_abct_memo: string | null;
    p37_abct_p37_abk_id: number;
    /* 文字型態廣告標題 */
    p37_abct_string_title: null | string;
    /* 圖片型態廣告標題 */
    title_file: p7_file;
    p37_abct_status: status;
    /** 指定連結，非附帶連結 */
    p37_abct_link?: string | null;
  };
}
type _AxiosResponse = AxiosResponse<responseData>;

interface responseData extends BackendResponse {
  response: typeInfo.response;
}

export default async () => {
  const getter = await $http(API);
  if (getter.data.code !== 0) throw getter;
  return getter;
};

export function fileFormater(file: p7_file) {
  if (!file) return null;
  const item = new FilesFormater(file).renderImage;
  return item;
}

export function jsonFormater(json: string) {
  try {
    const obj = JSON.parse(json);
    return obj;
  } catch (error) {
    ElMessageBox.alert("TradeAccount Error!");
    return {};
  }
}

/** 濾出指定的廣告區塊內容，並且格式化其內容的圖片
 * 當參數中有 id 時，會再輸出指定id的廣告
 * params為 string 因此需要轉為 number
 */
export function getAD(
  AD: typeInfo.p37_ad_advertise_block[],
  options: {
    type: "PC" | "MOBILE";
    block: number | string;
    id?: number | string;
  },
) {
  if (!AD) return [];
  const ADB = AD.filter(
    AD => AD.p37_abk_drive_type.toUpperCase() === options.type.toUpperCase(),
  ).find(AD => AD.p37_abk_block_number === Number(options.block));
  if (!ADB) return [];

  /** 計算廣告區塊的開始與結束時間 */
  const {
    p37_abk_start_time,
    p37_abk_end_time,
    p37_abk_name,
    p37_abk_status,
  } = ADB;
  const notStarted = p37_abk_start_time
    ? new Date().getTime() < p37_abk_start_time * 1000
    : false;
  const isEnded = p37_abk_end_time
    ? new Date().getTime() > p37_abk_end_time * 1000
    : false;
  const dsiabled = p37_abk_status === "-2";

  if (dsiabled || notStarted || isEnded) return [];

  const content = ADB?.content || [];
  const content_f = content
    .map(AD => ({
      ...AD,
      p37_abk_block_number: Number(options.block),
      p37_abk_drive_type: options.type.toUpperCase(),
      p37_abk_name,
      // title_file_f: fileFormater(AD.title_file),
    }))
    .sort((a, b) => {
      return b.p37_abct_id - a.p37_abct_id;
    });

  if (options.id) {
    return content_f.find(C => C.p37_abct_id === Number(options.id)) || {};
  }
  return content_f;
}

/** 濾出指定的版面內容，並且格式化其內容的圖片
 * params為 string 因此需要轉為 number
 */
export function getCMS(
  CMS: typeInfo.p36_cms_customization_block[],
  options: {
    type: "PC" | "MOBILE";
    block: number | string;
  },
) {
  if (!CMS) return [];
  return (
    CMS.filter(
      CMS =>
        CMS.p36_cbk_drive_type.toUpperCase() === options.type.toUpperCase(),
    ).find(CMS => CMS.p36_cbk_block_number === Number(options.block)) || {}
  );
}
