export enum STATUSCODE{
    default="default", //尚未申請
    pending="pending", //審核中
    approved="approved", //通過
    rejected="rejected", //拒絕
    disable="disable" //不能使用該功能
}
export enum ACTIVECODE{
    DISABLE = "0",
    ACTIVE = "1"
}